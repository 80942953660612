// src/DepthImage.js
import React, { useMemo, useRef, useState, useEffect } from 'react';
import { useLoader, useThree, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { useControls } from 'leva';

const DepthImage = ({ texture, onTextureLoad }) => {
  const displacementTexture = useLoader(THREE.TextureLoader, '/texture-depth.png');
  const { viewport } = useThree();
  const meshRef = useRef();
  const [angle, setAngle] = useState(0);
  const [direction, setDirection] = useState(1);

  const aspectRatio = texture.image.width / texture.image.height;
  const scaleFactor = 0.65; // 65% of the smaller dimension (width or height)

  const dimensions = useMemo(() => {
    const width = viewport.width * scaleFactor;
    const height = width / aspectRatio;
    return [width, height];
  }, [viewport.width, aspectRatio]);

  const { displacementScale, displacementBias, enableRotation } = useControls({
    displacementScale: {
      value: 2.0,
      min: 0.0,
      max: 20.0,
      step: 0.1,
    },
    displacementBias: {
      value: 0.0,
      min: -10.0,
      max: 10.0,
      step: 0.1,
    },
    enableRotation: {
      value: true,
    },
  });

  useFrame(() => {
    if (enableRotation) {
      const newAngle = angle + direction * 0.001; // Adjust the speed here
      if (newAngle > Math.PI / 18 || newAngle < -Math.PI / 18) {
        setDirection(-direction);
      }
      setAngle(newAngle);
      if (meshRef.current) {
        meshRef.current.rotation.y = newAngle;
      }
    }
  });

  // Update rotation to current angle when rotation is disabled
  useEffect(() => {
    if (!enableRotation && meshRef.current) {
      meshRef.current.rotation.y = angle;
    }
  }, [enableRotation, angle]);

  return (
    <mesh ref={meshRef}>
      <planeGeometry args={[dimensions[0], dimensions[1], 100, 100]} />
      <meshStandardMaterial
        map={texture}
        displacementMap={displacementTexture}
        displacementScale={displacementScale}
        displacementBias={displacementBias}
      />
    </mesh>
  );
};

export default DepthImage;
