// src/DepthBotHead.js
import React, { useRef, useState } from 'react';
import { useFrame } from '@react-three/fiber';
import { RoundedBox } from '@react-three/drei';

const DepthBotHead = () => {
  const groupRef = useRef();
  const [angle, setAngle] = useState(0);
  const [direction, setDirection] = useState(1);

  useFrame(() => {
    if (groupRef.current) {
      const newAngle = angle + direction * 0.0033;
      if (newAngle > Math.PI / 18 || newAngle < -Math.PI / 18) {
        setDirection(-direction);
      }
      setAngle(newAngle);
      groupRef.current.rotation.y = newAngle;
    }
  });

  return (
    <group ref={groupRef}>
      {/* Wireframe Rounded Cube */}
      <RoundedBox args={[2, 2, 2]} radius={0.2} smoothness={4}>
        <meshBasicMaterial color="green" wireframe />
      </RoundedBox>

      {/* Wireframe Disks */}
      <mesh position={[-0.3, 0.3, 1.01]} rotation={[Math.PI / 2, 0, 0]} scale={0.8}>
        <cylinderGeometry args={[0.3, 0.3, 0.1, 32]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>

      <mesh position={[0.3, 0.3, 1.01]} rotation={[Math.PI / 2, 0, 0]} scale={0.8}>
        <cylinderGeometry args={[0.3, 0.3, 0.1, 32]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>

      {/* Wireframe Rectangle */}
      <mesh position={[0, -0.3, 1.01]} scale={[1.2, 0.3, 0.1]}>
        <boxGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>

      {/* Medium-sized flat cylinder on top of the cube */}
      <mesh position={[0, 1.1, 0]}>
        <cylinderGeometry args={[0.5, 0.5, 0.1, 32]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>

      {/* Long tall skinny cylinder on top of the flat cylinder */}
      <mesh position={[0, 1.65, 0]}>
        <cylinderGeometry args={[0.1, 0.1, 1, 32]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>

      {/* Small ball on top of the tall skinny cylinder */}
      <mesh position={[0, 2.2, 0]}>
        <sphereGeometry args={[0.15, 32, 32]} />
        <meshBasicMaterial color="green" wireframe />
      </mesh>
    </group>
  );
};

export default DepthBotHead;
