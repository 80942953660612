// src/App.js
import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Canvas, useLoader } from '@react-three/fiber';
import { OrbitControls } from '@react-three/drei';
import { EffectComposer, Glitch } from '@react-three/postprocessing';
import * as THREE from 'three';
import DepthBotHead from './DepthBotHead';
import DepthImage from './DepthImage';

const App = () => {
  const [showRobotHead, setShowRobotHead] = useState(true);
  const [texture, setTexture] = useState(null);
  const canvasRef = useRef();

  const handleClick = () => {
    setShowRobotHead(false);
  };

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const newTexture = new THREE.Texture(img);
          newTexture.needsUpdate = true;
          setTexture(newTexture);
        };
      };
      reader.readAsDataURL(file);
    }
  }, []);

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener('dragover', handleDragOver);
      canvas.addEventListener('drop', handleDrop);
      return () => {
        canvas.removeEventListener('dragover', handleDragOver);
        canvas.removeEventListener('drop', handleDrop);
      };
    }
  }, [handleDragOver, handleDrop]);

  const initialTexture = useLoader(THREE.TextureLoader, '/texture.jpg');

  return (
    <div style={{ position: 'relative', height: '100vh' }}>
      <Canvas ref={canvasRef} style={{ background: 'black', width: '100%', height: '100%' }}>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        {showRobotHead ? <DepthBotHead /> : <DepthImage texture={texture || initialTexture} />}
        {showRobotHead && (
          <EffectComposer>
            <Glitch
              delay={[4, 8]} // Increased delay between glitches
              duration={[0.1, 0.3]} // Shortened glitch duration
              strength={[0.05, 0.2]} // Reduced glitch strength
            />
          </EffectComposer>
        )}
        <OrbitControls />
      </Canvas>
      {showRobotHead && (
        <>
          <h1
            style={{
              position: 'absolute',
              top: '60px',
              left: '50%',
              transform: 'translateX(-50%)',
              color: 'green',
              fontSize: '3em',
              margin: 0,
              padding: 0,
              textAlign: 'center',
              fontFamily: "'Black Ops One', sans-serif",
            }}
          >
            Welcome to DepthBot
          </h1>
          <button
            onClick={handleClick}
            style={{
              position: 'absolute',
              bottom: '40px',
              left: '50%',
              transform: 'translateX(-50%)',
              padding: '10px 20px',
              fontSize: '16px',
              color: 'green',
              backgroundColor: 'black',
              border: '2px solid green',
              cursor: 'pointer',
              fontFamily: "'Black Ops One', sans-serif",
            }}
          >
            Enter
          </button>
        </>
      )}
    </div>
  );
};

export default App;
